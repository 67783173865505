import AffiliaterCell from "./AffiliaterCell";
import AdvertiserCell from "./AdvertiserCell";
import RegistrationCell from "./RegistrationCell";
import adImageCell from "./AdImageCell";
import IntegrationCell from "./IntegrationCell";

const cells: Record<string, any> = {
    affiliater: AffiliaterCell,
    advertiser: AdvertiserCell,
    registration: RegistrationCell,
    integration: IntegrationCell,
    adImage: adImageCell,
};

export default cells;
