export const CREATE_INTEGRATION = (gql: any) => gql`
    mutation($data: CreateIntegrationInput!) {
        createIntegration(data: $data) {
            id
        }
    }
`;
export const GET_INTEGRATION = (gql: any) => gql`
    query($id: ID!) {
        getIntegration(id: $id) {
            id
            level
            user
            game
            gameCode
            gameName
            organization
            organizationName
            campaign
            campaignCode
            campaignName
            partner
            partnerCode
            partnerName
            tenant
            tenantCode
            tenantName
            code
            name
            createdAt
            updatedAt
            status
        }
    }
`;
export const FIND_INTEGRATIONS = (gql: any) => gql`
    query findIntegrations($offset: String, $limit: Int) {
        findIntegrations(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                level
                user
                game
                gameCode
                gameName
                organization
                organizationName
                campaign
                campaignCode
                campaignName
                partner
                partnerCode
                partnerName
                tenant
                tenantCode
                tenantName
                code
                name
                createdAt
                updatedAt
                status
            }
        }
    }
`;
export const SEARCH_ALL_INTEGRATIONS = (gql: any) => gql`
    query searchIntegrations($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchAllIntegrations:searchIntegrations(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                level
                user
                game
                gameCode
                gameName
                organization
                organizationName
                campaign
                campaignCode
                campaignName
                partner
                partnerCode
                partnerName
                tenant
                tenantCode
                tenantName
                code
                name
                createdAt
                updatedAt
                status
            }
        }
    }
`;
export const SEARCH_GAME_INTEGRATIONS = (gql: any) => gql`
    query searchGameIntegrations($id: String, $offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchIntegrations(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "game", type: "string", value: $id, operator: "eq"}
            ]
        }) {
            cursor
            count
            items {
                id
                organization
                organizationName
                name
                createdAt
                updatedAt
                status
            }
        }
    }
`;

export const SEARCH_GAMELEVEL_INTEGRATIONS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchGamelevelIntegrations:searchIntegrations(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "level", type: "string", value: "game", operator: "eq"}
            ]
        }) {
            cursor
            count
            items {
                id
                level
                user
                game
                gameCode
                gameName
                organization
                organizationName
                campaign
                campaignCode
                campaignName
                partner
                partnerCode
                partnerName
                tenant
                tenantCode
                tenantName
                code
                name
                createdAt
                updatedAt
                status
            }
        }
    }
`;
export const SEARCH_ORGLEVELHELLOASSO_INTEGRATIONS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchOrglevelhelloassoIntegrations:searchIntegrations(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "level", type: "string", value: "organization", operator: "eq"},
                {field: "code", type: "string", value: "helloasso", operator: "eq"},
            ]
        }) {
            cursor
            count
            items {
                id
                level
                user
                game
                gameCode
                gameName
                organization
                organizationName
                campaign
                campaignCode
                campaignName
                partner
                partnerCode
                partnerName
                tenant
                tenantCode
                tenantName
                code
                name
                createdAt
                updatedAt
                status
            }
        }
    }
`;
export const SEARCH_ORGLEVELLYDIA_INTEGRATIONS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchOrglevellydiaIntegrations:searchIntegrations(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "level", type: "string", value: "organization", operator: "eq"},
                {field: "code", type: "string", value: "lydia", operator: "eq"},
            ]
        }) {
            cursor
            count
            items {
                id
                level
                user
                game
                gameCode
                gameName
                organization
                organizationName
                campaign
                campaignCode
                campaignName
                partner
                partnerCode
                partnerName
                tenant
                tenantCode
                tenantName
                code
                name
                createdAt
                updatedAt
                status
            }
        }
    }
`;
export const SEARCH_ORGLEVELSUMUP_INTEGRATIONS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchOrglevelsumupIntegrations:searchIntegrations(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "level", type: "string", value: "organization", operator: "eq"},
                {field: "code", type: "string", value: "sumup", operator: "eq"},
            ]
        }) {
            cursor
            count
            items {
                id
                level
                user
                game
                gameCode
                gameName
                organization
                organizationName
                campaign
                campaignCode
                campaignName
                partner
                partnerCode
                partnerName
                tenant
                tenantCode
                tenantName
                code
                name
                createdAt
                updatedAt
                status
            }
        }
    }
`;
export const SEARCH_ORGLEVELGOCARDLESS_INTEGRATIONS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchOrglevelgocardlessIntegrations:searchIntegrations(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "level", type: "string", value: "organization", operator: "eq"},
                {field: "code", type: "string", value: "gocardless", operator: "eq"},
            ]
        }) {
            cursor
            count
            items {
                id
                level
                user
                game
                gameCode
                gameName
                organization
                organizationName
                campaign
                campaignCode
                campaignName
                partner
                partnerCode
                partnerName
                tenant
                tenantCode
                tenantName
                code
                name
                createdAt
                updatedAt
                status
            }
        }
    }
`;
export const SEARCH_ORGLEVELSTRIPE_INTEGRATIONS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchOrglevelstripeIntegrations:searchIntegrations(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "level", type: "string", value: "organization", operator: "eq"},
                {field: "code", type: "string", value: "stripe", operator: "eq"},
            ]
        }) {
            cursor
            count
            items {
                id
                level
                user
                game
                gameCode
                gameName
                organization
                organizationName
                campaign
                campaignCode
                campaignName
                partner
                partnerCode
                partnerName
                tenant
                tenantCode
                tenantName
                code
                name
                createdAt
                updatedAt
                status
            }
        }
    }
`;
export const SEARCH_ORGLEVELPAYPAL_INTEGRATIONS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchOrglevelpaypalIntegrations:searchIntegrations(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "level", type: "string", value: "organization", operator: "eq"},
                {field: "code", type: "string", value: "paypal", operator: "eq"},
            ]
        }) {
            cursor
            count
            items {
                id
                level
                user
                game
                gameCode
                gameName
                organization
                organizationName
                campaign
                campaignCode
                campaignName
                partner
                partnerCode
                partnerName
                tenant
                tenantCode
                tenantName
                code
                name
                createdAt
                updatedAt
                status
            }
        }
    }
`;
export const SEARCH_ORGLEVEL_INTEGRATIONS = (gql: any) => gql`
    query($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchOrglevelIntegrations:searchIntegrations(offset: $offset, limit: $limit, sort: $sort, query: {
            criteria: [
                {field: "level", type: "string", value: "organization", operator: "eq"}
            ]
        }) {
            cursor
            count
            items {
                id
                level
                user
                game
                gameCode
                gameName
                organization
                organizationName
                campaign
                campaignCode
                campaignName
                partner
                partnerCode
                partnerName
                tenant
                tenantCode
                tenantName
                code
                name
                createdAt
                updatedAt
                status
            }
        }
    }
`;
