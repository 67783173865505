import { MouseEventHandler, ReactElement, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {BaseCell} from "@gotombola/react-as/lib/types";

export function IntegrationCell({row: { integration, integrationName }}: IntegrationCellProps): ReactElement {
    const history = useHistory();
    const integrationUri = `/integrations/${integration}`;

    const onIntegrationClick = useCallback<MouseEventHandler<HTMLAnchorElement>>(
        (e) => {
            e.stopPropagation();
            e.preventDefault();
            history.push(integrationUri);
        },
        [history, integrationUri],
    );

    return (
        <>
            <a href={integrationUri} onClick={onIntegrationClick} className={'hover:underline'}>
              {integrationName} - {integration}
            </a>
        </>
    );
}

export type IntegrationCellProps = BaseCell;

// noinspection JSUnusedGlobalSymbols
export default IntegrationCell;
