export const CREATE_TICKETATTRIBUTION = (gql: any) => gql`
    mutation($data: CreateTicketattributionInput!) {
        createTicketattribution(data: $data) {
            id
        }
    }
`;
export const DELETE_TICKETATTRIBUTION = (gql: any) => gql`
    mutation($id: ID!) {
        deleteTicketattribution(id: $id) {
            id
        }
    }
`;
export const GET_TICKETATTRIBUTION = (gql: any) => gql`
    query($id: ID!) {
        getTicketattribution(id: $id) {
            abortedAt
            abortedBy
            completedAt
            completedBy
            createdAt
            createdBy
            erroredAt
            erroredBy
            game
            gameCode
            gameName
            id
            organization
            organizationCode
            organizationName
            partner
            partnerCode
            partnerName
            pausedAt
            pausedBy
            privateToken
            publicToken
            startedAt
            startedBy
            status
            tags
            tenant
            tenantCode
            tenantName
            token
            updatedAt
        }
    }
`;
export const FIND_TICKETATTRIBUTIONS = (gql: any) => gql`
    query findTicketattributions($offset: String, $limit: Int) {
        findTicketattributions(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                abortedAt
                abortedBy
                completedAt
                completedBy
                createdAt
                createdBy
                erroredAt
                erroredBy
                game
                gameCode
                gameName
                id
                organization
                organizationCode
                organizationName
                partner
                partnerCode
                partnerName
                pausedAt
                pausedBy
                privateToken
                publicToken
                startedAt
                startedBy
                status
                tags
                tenant
                tenantCode
                tenantName
                token
                updatedAt
            }
        }
    }
`;
export const SEARCH_TICKETATTRIBUTIONS = (gql: any) => gql`
    query searchTicketattributions($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchTicketattributions(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                abortedAt
                abortedBy
                completedAt
                completedBy
                createdAt
                createdBy
                erroredAt
                erroredBy
                game
                gameCode
                gameName
                id
                organization
                organizationCode
                organizationName
                partner
                partnerCode
                partnerName
                pausedAt
                pausedBy
                privateToken
                publicToken
                startedAt
                startedBy
                status
                tags
                tenant
                tenantCode
                tenantName
                token
                updatedAt
            }
        }
    }
`;
