const fields = {
    advertiser: { type: 'querySelect', kind: 'advertiser', query: 'FIND_ADVERTISERS', dataKey: 'findAdvertisers' },
    affiliater: { type: 'querySelect', kind: 'affiliater', query: 'FIND_AFFILIATERS', dataKey: 'findAffiliaters' },
    agenda: { type: 'textarea', kind: 'agenda' },
    allowedParticipants: {
        type: 'select', kind: 'allowedParticipants', values: [
            { value: 'all', label: 'allowedparticipants_all_label' },
            { value: 'employees', label: 'allowedparticipants_employees_label' },
            { value: 'employees+students', label: 'allowedparticipants_employeesstudents_label' },
            { value: 'members', label: 'allowedparticipants_members_label' },
            { value: 'students', label: 'allowedparticipants_students_label' },
        ]
    },
    assetType: {
        type: 'select', kind: 'type', values: [
            { value: 'banner', label: 'assettype_banner_label' },
            { value: 'logo', label: 'assettype_logo_label' },
            { value: 'image', label: 'assettype_image_label' },
        ]
    },
    attachments: { type: 'textarea', kind: 'attachments' },
    audioTags: {
        type: 'tags',
        kind: 'tags',
        tags: [
            { value: 'primary', label: 'tag_audio_primary_label' },
            { value: 'secondary', label: 'tag_audio_secondary_label' },
            { value: 'tertiary', label: 'tag_audio_tertiary_label' },
            { value: 'logos', label: 'tag_audio_logos_label' },
            { value: 'sponsors', label: 'tag_audio_sponsors_label' },
            { value: 'sponsors_logos', label: 'tag_audio_sponsors_logos_label' },
            { value: 'events', label: 'tag_audio_events_label' },
            { value: 'members', label: 'tag_audio_members_label' },
            { value: 'results', label: 'tag_audio_results_label' },
            { value: 'hidden', label: 'tag_audio_hidden_label' },
        ],
    },
    automation: { type: 'querySelect', kind: 'automation', convert: (item: any) => ({ value: item.id, label: `${item.code}` }) },
    automationDefinition: { type: 'yaml', kind: 'definition' },
    backgroundAudioUrl: { type: 'url', kind: 'backgroundAudioUrl' },
    mainLongAudioUrl: { type: 'url', kind: 'mainLongAudioUrl' },
    mainShortAudioUrl: { type: 'url', kind: 'mainShortAudioUrl' },
    beneficiary: { type: 'querySelect', kind: 'beneficiary', query: 'FIND_BENEFICIARIES', dataKey: 'findBeneficiaries' },
    blacklistedAds: { type: 'querySelect', kind: 'blacklistedAds', query: 'FIND_ADS', dataKey: 'findAds', multiple: true, convert: (item: any) => ({ value: item.id, label: `${item.code}-${item.name}` }) },
    blacklistedAdvertisers: { type: 'querySelect', kind: 'blacklistedAdvertisers', query: 'FIND_ADVERTISERS', dataKey: 'findAdvertisers', multiple: true, convert: (item: any) => ({ value: item.id, label: item.name }) },
    blacklistedAffiliaters: { type: 'querySelect', kind: 'blacklistedAffiliaters', query: 'FIND_AFFILIATERS', dataKey: 'findAffiliaters', multiple: true, convert: (item: any) => ({ value: item.id, label: item.name }) },
    blacklistedTags: { type: 'querySelect', kind: 'blacklistedTags', query: 'FIND_TAGS_BY_TYPE', dataKey: 'findTagsByType', multiple: true, options: () => ({variables: {type: 'ad'}}), convert: (item: any) => ({ value: item.code, label: item.name }) },
    blacklistedCountries: { type: 'querySelect', kind: 'blacklistedCountries', query: 'FIND_TCOUNTRIES', dataKey: 'findTcountries', multiple: true, convert: (item: any) => ({ value: item.code, label: item.name }) },
    bodyFile: { type: 'file', kind: 'bodyFile' },
    bodyTextFile: { type: 'file', kind: 'bodyTextFile' },
    bonusMessage: { type: 'text', kind: 'bonusMessage' },
    bookList: { type: 'text', kind: 'bookList' },
    booksetsPresets: { type: 'querySelect', kind: 'presets', query: 'FIND_PRESETS_OF_TYPE_BOOKSET', dataKey: 'findPresetsOfTypeBookset', multiple: true, convert: (item: any) => ({ value: item.code, label: item.name }) },
    glabelsPresets: { type: 'querySelect', kind: 'presets', query: 'FIND_PRESETS_OF_TYPE_GLABEL', dataKey: 'findPresetsOfTypeGlabel', multiple: true, convert: (item: any) => ({ value: item.code, label: item.name }) },
    olabelsPresets: { type: 'querySelect', kind: 'presets', query: 'FIND_PRESETS_OF_TYPE_OLABEL', dataKey: 'findPresetsOfTypeOlabel', multiple: true, convert: (item: any) => ({ value: item.code, label: item.name }) },
    gkeyfiguresPresets: { type: 'querySelect', kind: 'presets', query: 'FIND_PRESETS_OF_TYPE_GKEYFIGURE', dataKey: 'findPresetsOfTypeGkeyfigure', multiple: true, convert: (item: any) => ({ value: item.code, label: item.name }) },
    okeyfiguresPresets: { type: 'querySelect', kind: 'presets', query: 'FIND_PRESETS_OF_TYPE_OKEYFIGURE', dataKey: 'findPresetsOfTypeOkeyfigure', multiple: true, convert: (item: any) => ({ value: item.code, label: item.name }) },
    brand: { type: 'querySelect', kind: 'brand', convert: (item: any) => ({ value: item.id, label: `${item.code} - ${item.name}` }) },
    bunch: { type: 'querySelect', kind: 'bunch', query: 'FIND_BUNCHES', dataKey: 'findBunches', convert: (item: any) => ({ value: item.id, label: `${item.code} - ${item.name}` }) },
    bunchRank: { type: 'number', kind: 'bunchRank' },
    bunchTags: {
        type: 'tags',
        kind: 'tags',
        tags: [
            { value: 'primary', label: 'tag_image_primary_label' },
            { value: 'secondary', label: 'tag_image_secondary_label' },
            { value: 'tertiary', label: 'tag_image_tertiary_label' },
            { value: 'logos', label: 'tag_image_logos_label' },
            { value: 'sponsors', label: 'tag_image_sponsors_label' },
            { value: 'sponsors_logos', label: 'tag_image_sponsors_logos_label' },
            { value: 'events', label: 'tag_image_events_label' },
            { value: 'members', label: 'tag_image_members_label' },
            { value: 'results', label: 'tag_image_results_label' },
            { value: 'hidden', label: 'tag_image_hidden_label' },
        ],
    },
    campaign: { type: 'querySelect', kind: 'campaign' },
    category: { type: 'text', kind: 'category' },
    creditLevel: {type: 'select', kind: 'level', values: [
            {value: 'tenant', label: 'tenant'},
            {value: 'campaign', label: 'campaign'},
            {value: 'partner', label: 'partner'},
            {value: 'organization', label: 'organization'},
            {value: 'game', label: 'game'},
            {value: 'seller', label: 'seller'},
            {value: 'sellergroup', label: 'sellergroup'},
            {value: 'user', label: 'user'},
        ]
    },
    customPublicPageUrl: { type: 'text', kind: 'customPublicPageUrl' },
    customTermsPageUrl: { type: 'url', kind: 'customTermsPageUrl' },
    customResultsPageUrl: { type: 'url', kind: 'customResultsPageUrl' },
    codeDefinition: { type: 'yaml', kind: 'definition' },
    commission: { type: 'number', kind: 'commission' },
    content: { type: 'textarea', kind: 'content' },
    customBonuses: { type: 'text', kind: 'customBonuses' },
    customCode: { type: 'text', kind: 'customCode' },
    customOwnerForm: { type: 'text', kind: 'customOwnerForm' },
    customPacks: { type: 'text', kind: 'customPacks' },
    dashboardDefinition: {type: 'yaml', kind: 'definition'},
    dashboardsLevel: {type: 'select', kind: 'level', values: [
            {value: 'tenant', label: 'tenant'},
            {value: 'campaign', label: 'campaign'},
            {value: 'partner', label: 'partner'},
            {value: 'organization', label: 'organization'},
            {value: 'game', label: 'game'},
            {value: 'seller', label: 'seller'},
            {value: 'sellergroup', label: 'sellergroup'},
            {value: 'grouping', label: 'grouping'},
        ]
    },
    dashboardsPresets: { type: 'querySelect', kind: 'presets', query: 'FIND_PRESETS_OF_TYPE_DASHBOARD', dataKey: 'findPresetsOfTypeDashboard', multiple: true, convert: (item: any) => ({ value: item.code, label: item.name }) },
    data: { type: 'textarea', kind: 'data' },
    dataqueriesPresets: { type: 'querySelect', kind: 'presets', query: 'FIND_PRESETS_OF_TYPE_DATAQUERY', dataKey: 'findPresetsOfTypeDataquery', multiple: true, convert: (item: any) => ({ value: item.code, label: item.name }) },
    definition: { type: 'text', kind: 'definition' },
    definitionFormat: {
        type: 'select', kind: 'definitionFormat', values: [
            { value: 'json', label: 'json' },
            { value: 'raw', label: 'raw' },
        ]
    },
    drawMode: {
        type: 'select', kind: 'drawMode', values: [
            { value: 'automatic', label: 'automatic' },
            { value: 'manual', label: 'manual' }
        ]
    },
    externalId: { type: 'text', kind: 'externalId' },
    objectId: { type: 'text', kind: 'objectId' },
    integrationLevel: {
        type: 'select', kind: 'level', values: [
            { value: 'tenant', label: 'tenant' },
            { value: 'campaign', label: 'campaign' },
            { value: 'partner', label: 'partner' },
            { value: 'organization', label: 'organization' },
            { value: 'game', label: 'game' },
            { value: 'user', label: 'user' },
            { value: 'unknown', label: 'unknown' },
        ]
    },
    sponsoringLevel: {
        type: 'select', kind: 'level', values: [
            { value: '', label: '-' },
            { value: 'coowner', label: 'coowner' },
        ]
    },
    details: { type: 'textarea', kind: 'details' },
    draw: { type: 'querySelect', kind: 'draw', convert: (item: any) => ({ value: item.id, label: `${item.code}` }) },
    adTags: { type: 'querySelect', kind: 'tags', query: 'FIND_TAGS_BY_TYPE', dataKey: 'findTagsByType', options: () => ({variables: {type: 'ad'}}), multiple: true, convert: (item: any) => ({ value: item.code, label: `${item.name}` }) },
    creditTags: { type: 'querySelect', kind: 'tags', query: 'FIND_TAGS_BY_TYPE', dataKey: 'findTagsByType', options: () => ({variables: {type: 'credit'}}), multiple: true, convert: (item: any) => ({ value: item.code, label: `${item.name}` }) },
    emailFromArn: { type: 'text', kind: 'emailFromArn' },
    emailFromEmail: { type: 'email', kind: 'emailFromEmail' },
    emailFrom: { type: 'text', kind: 'emailFrom' },
    emailsender: { type: 'querySelect', kind: 'emailsender', convert: (item: any) => ({ value: item.id, label: `${item.name} <${item.email}>` }) },
    endAt: { type: 'dateTime', kind: 'endAt' },
    startDate: { type: 'dateTime', kind: 'startDate' },
    endDate: { type: 'dateTime', kind: 'endDate' },
    externalTrackerId: { type: 'text', kind: 'externalTrackerId' },
    featured: {
        type: 'select', kind: 'featured', values: [
            { value: true, label: 'featured_enabled_label' },
            { value: false, label: 'featured_disabled_label' },
        ]
    },
    features: {
        type: 'featuresSelect', kind: 'features', values: [
            { value: 'promise', label: 'feature_promise' },
            { value: 'stripe', label: 'feature_stripe' },
            { value: 'helloasso', label: 'feature_helloasso' },
            { value: 'paypal', label: 'feature_paypal' },
            { value: 'lydia', label: 'feature_lydia' },
        ]
    },
    flexModeStatus: {
        type: 'select', kind: 'flexModeStatus', values: [
            { value: 'ACTIVATED', label: 'flexmodestatus_enabled_label' },
            { value: 'NOT_ACTIVATED', label: 'flexmodestatus_disabled_label' },
        ]
    },
    flyerVideoUrl: { type: 'url', kind: 'flyerVideoUrl' },
    frequency: {
        type: 'select', kind: 'frequency', values: [
            { value: 'hour_quarterly', label: 'hour_quarterly' },
            { value: 'hourly', label: 'hourly' },
            { value: 'daily', label: 'daily' },
            { value: 'weekly', label: 'weekly' },
            { value: 'biweekly', label: 'biweekly' },
            { value: 'monthly', label: 'monthly' },
            { value: 'trimesterly', label: 'trimesterly' },
            { value: 'semesterly', label: 'semesterly' },
            { value: 'yearly', label: 'yearly' },
            { value: 'none', label: 'none' },
        ]
    },
    shareMode: {
        type: 'select', kind: 'shareMode', values: [
            { value: 'NOT_SHARED', label: 'not_shared' },
            { value: 'SHARED_TENANT', label: 'shared_tenant' },
            { value: 'SHARED_CAMPAIGN', label: 'shared_campaign' },
            { value: 'SHARED_PARTNER', label: 'shared_partner' },
            { value: 'SHARED_ORGANIZATION', label: 'shared_organization' },
            { value: 'SHARED_GAME', label: 'shared_game' },
            { value: 'SHARED_GROUPING', label: 'shared_grouping' },
            { value: 'SHARED_SELLERGROUP', label: 'shared_sellergroup' },
            { value: 'SHARED_SELLER', label: 'shared_seller' },
        ]
    },
    game: { type: 'querySelect', kind: 'game', convert: (item: any) => ({ value: item.id, label: `${item.code} - ${item.name} (${item.organizationCode})` }) },
    gameType: {
        type: 'typeSelect', kind: 'type', values: [
            { value: 'raffle', label: 'field_gametype_raffle' },
            { value: 'lottery', label: 'field_gametype_lottery' },
            { value: 'donation', label: 'field_gametype_donation' },
            { value: 'dinner', label: 'field_gametype_dinner' },
        ]
    },
    gameSellergroupType: {
        type: 'select', kind: 'sellergroupType', values: [
            { value: 'club', label: 'field_sellergrouptype_club' },
            { value: 'city', label: 'field_sellergrouptype_city' },
            { value: 'country', label: 'field_sellergrouptype_country' },
            { value: 'classroom', label: 'field_sellergrouptype_classroom' },
            { value: 'team', label: 'field_sellergrouptype_team' },
            { value: 'barracks', label: 'field_sellergrouptype_barracks' },
            { value: 'branch', label: 'field_sellergrouptype_branch' },
            { value: 'group', label: 'field_sellergrouptype_group' },
        ]
    },
    sellergroupSortMode: {
        type: 'select', kind: 'sellergroupSortMode', values: [
            { value: 'default', label: 'field_sellergroupsortmode_default' },
            { value: 'rank', label: 'field_sellergroupsortmode_rank' },
            { value: 'alpha', label: 'field_sellergroupsortmode_alpha' },
        ]
    },
    sellergroupType: {
        type: 'typeSelect', kind: 'type', values: [
            { value: 'club', label: 'field_sellergrouptype_club' },
            { value: 'city', label: 'field_sellergrouptype_city' },
            { value: 'country', label: 'field_sellergrouptype_country' },
            { value: 'classroom', label: 'field_sellergrouptype_classroom' },
            { value: 'team', label: 'field_sellergrouptype_team' },
            { value: 'barracks', label: 'field_sellergrouptype_barracks' },
            { value: 'branch', label: 'field_sellergrouptype_branch' },
            { value: 'group', label: 'field_sellergrouptype_group' },
        ]
    },
    identityArn: { type: 'text', kind: 'identityArn' },
    imageTags: {
        type: 'tags',
        kind: 'tags',
        tags: [
            { value: 'primary', label: 'tag_image_primary_label' },
            { value: 'secondary', label: 'tag_image_secondary_label' },
            { value: 'tertiary', label: 'tag_image_tertiary_label' },
            { value: 'logos', label: 'tag_image_logos_label' },
            { value: 'sponsors', label: 'tag_image_sponsors_label' },
            { value: 'sponsors_logos', label: 'tag_image_sponsors_logos_label' },
            { value: 'events', label: 'tag_image_events_label' },
            { value: 'members', label: 'tag_image_members_label' },
            { value: 'results', label: 'tag_image_results_label' },
            { value: 'hidden', label: 'tag_image_hidden_label' },
        ],
    },
    assetTags: {
        type: 'tags',
        kind: 'tags',
        tags: [
            { value: 'primary', label: 'tag_image_primary_label' },
            { value: 'secondary', label: 'tag_image_secondary_label' },
            { value: 'tertiary', label: 'tag_image_tertiary_label' },
            { value: 'logos', label: 'tag_image_logos_label' },
            { value: 'sponsors', label: 'tag_image_sponsors_label' },
            { value: 'sponsors_logos', label: 'tag_image_sponsors_logos_label' },
            { value: 'events', label: 'tag_image_events_label' },
            { value: 'members', label: 'tag_image_members_label' },
            { value: 'results', label: 'tag_image_results_label' },
            { value: 'hidden', label: 'tag_image_hidden_label' },
        ],
    },
    infos: { type: 'textarea', kind: 'infos' },
    inlinePreset: { type: 'text', kind: 'inlinePreset' },
    integrationParams: { type: 'textarea', kind: 'params', label: 'field_integrationparams_label', helper: 'field_integrationparams_helper', placeholder: 'field_integrationparams_placeholder' },
    invoicePresets: { type: 'querySelect', kind: 'presets', query: 'FIND_PRESETS_OF_TYPE_INVOICE', dataKey: 'findPresetsOfTypeInvoice', multiple: true, convert: (item: any) => ({ value: item.code, label: item.name }) },
    itemType: { type: 'text', kind: 'itemType' },
    job: { type: 'querySelect', kind: 'job' },
    jobType: { type: 'text', kind: 'jobType' },
    gachievementPresets: { type: 'querySelect', kind: 'presets', query: 'FIND_PRESETS_OF_TYPE_GACHIEVEMENT', dataKey: 'findPresetsOfTypeGachievement', multiple: true, convert: (item: any) => ({ value: item.code, label: item.name }) },
    gattributableMode: {
        type: 'select', kind: 'mode', values: [
            { value: 'sale', label: 'sale' },
            { value: 'sale_paid', label: 'sale_paid' },
            { value: 'ticket', label: 'ticket' },
            { value: 'ticket_paid', label: 'ticket_paid' },
        ]
    },
    gattributableSubtype: {
        type: 'select', kind: 'subtype', values: [
            { value: 'child', label: 'child' },
            { value: 'cat', label: 'cat' },
            { value: 'dog', label: 'dog' },
            { value: 'rabbit', label: 'rabbit' },
            { value: 'horse', label: 'horse' },
            { value: 'lion', label: 'lion' },
            { value: 'panda', label: 'panda' },
        ]
    },
    gattributableType: {
        type: 'select', kind: 'type', values: [
            { value: 'person', label: 'person' },
            { value: 'pet', label: 'pet' },
            { value: 'animal', label: 'animal' },
            { value: 'country', label: 'country' },
            { value: 'color', label: 'color' },
            { value: 'object', label: 'object' },
        ]
    },
    godfather: { type: 'querySelect', kind: 'godfather' },
    godfather2Image: { type: 'imageUpload', kind: 'godfather2Image' },
    godfather2Subtitle: { type: 'text', kind: 'godfather2Subtitle' },
    godfather2Title: { type: 'title', kind: 'godfather2Title' },
    godfather3Image: { type: 'imageUpload', kind: 'godfather3Image' },
    godfather3Subtitle: { type: 'text', kind: 'godfather3Subtitle' },
    godfather3Title: { type: 'title', kind: 'godfather3Title' },
    godfatherImage: { type: 'imageUpload', kind: 'godfatherImage' },
    godfatherSubtitle: { type: 'text', kind: 'godfatherSubtitle' },
    godfatherTitle: { type: 'title', kind: 'godfatherTitle' },
    godfather2VideoUrl: { type: 'url', kind: 'godfather2VideoUrl' },
    godfather3VideoUrl: { type: 'url', kind: 'godfather3VideoUrl' },
    godfatherVideoUrl: { type: 'url', kind: 'godfatherVideoUrl' },
    groupingPresets: { type: 'querySelect', kind: 'presets', query: 'FIND_PRESETS_OF_TYPE_GROUPING', dataKey: 'findPresetsOfTypeGrouping', multiple: true, convert: (item: any) => ({ value: item.code, label: item.name }) },
    gamePresets: { type: 'querySelect', kind: 'presets', query: 'FIND_PRESETS_OF_TYPE_GAME', dataKey: 'findPresetsOfTypeGame', multiple: true, convert: (item: any) => ({ value: item.code, label: item.name }) },
    index: { type: 'text', kind: 'index' },
    invitationPresets: { type: 'querySelect', kind: 'presets', query: 'FIND_PRESETS_OF_TYPE_INVITATION', dataKey: 'findPresetsOfTypeInvitation', multiple: true, convert: (item: any) => ({ value: item.code, label: item.name }) },
    godfathersRankingsEnabled: {
        type: 'select', kind: 'godfathersRankingsEnabled', values: [
            { value: true, label: 'godfathersrankings_enabled_label' },
            { value: false, label: 'godfathersrankings_disabled_label' },
        ]
    },
    leaderboardEnabled: {
        type: 'select', kind: 'leaderboardEnabled', values: [
            { value: true, label: 'leaderboard_enabled_label' },
            { value: false, label: 'leaderboard_disabled_label' },
        ]
    },
    sellergroupGroupingsDisabled: {
        type: 'select', kind: 'sellergroupGroupingsDisabled', values: [
            { value: true, label: 'sellergroupgroupingsdisabled_enabled_label' },
            { value: false, label: 'sellergroupgroupingsdisabled_disabled_label' },
        ]
    },
    limit: { type: 'number', kind: 'limit' },
    locationCountry: { type: 'country', kind: 'locationCountry' },
    locationCity: { type: 'city', kind: 'locationCity' },
    locationComplement: { type: 'complement', kind: 'locationComplement' },
    locationDescription: { type: 'textarea', kind: 'locationDescription' },
    locationEmail: { type: 'number', kind: 'locationEmail' },
    locationLatitude: { type: 'number', kind: 'locationLatitude' },
    locationLongitude: { type: 'number', kind: 'locationLongitude' },
    locationLogoImage: { type: 'imageUpload', kind: 'locationLogoImage' },
    qrcodeLogoImage: { type: 'imageUpload', kind: 'qrcodeLogoImage' },
    locationMainImage: { type: 'imageUpload', kind: 'locationMainImage' },
    image1Image: { type: 'imageUpload', kind: 'image1Image' },
    image2Image: { type: 'imageUpload', kind: 'image2Image' },
    image3Image: { type: 'imageUpload', kind: 'image3Image' },
    image4Image: { type: 'imageUpload', kind: 'image4Image' },
    image5Image: { type: 'imageUpload', kind: 'image5Image' },
    image6Image: { type: 'imageUpload', kind: 'image6Image' },
    image7Image: { type: 'imageUpload', kind: 'image7Image' },
    image8Image: { type: 'imageUpload', kind: 'image8Image' },
    image9Image: { type: 'imageUpload', kind: 'image9Image' },
    locationFacebookUrl: { type: 'url', kind: 'locationFacebookUrl' },
    locationMainAudioUrl: { type: 'url', kind: 'locationMainAudioUrl' },
    locationMainVideoUrl: { type: 'url', kind: 'locationMainVideoUrl' },
    locationName: { type: 'name', kind: 'locationName' },
    locationPhone: { type: 'phone', kind: 'locationPhone' },
    locationPresentation: { type: 'textarea', kind: 'locationPresentation' },
    locationState: { type: 'state', kind: 'locationState' },
    locationStreet: { type: 'street', kind: 'locationStreet' },
    locationTwitterUrl: { type: 'url', kind: 'locationTwitterUrl' },
    locationWebsiteUrl: { type: 'url', kind: 'locationWebsiteUrl' },
    locationYoutubeUrl: { type: 'url', kind: 'locationYoutubeUrl' },
    locationZipCode: { type: 'zipCode', kind: 'locationZipCode' },
    lock: {
        type: 'select', kind: 'lock', values: [
            { value: true, label: 'lock_enabled_label' },
            { value: false, label: 'lock_disabled_label' },
        ]
    },
    longDescription: { type: 'textarea', kind: 'longDescription' },
    longDefinition: { type: 'textarea', kind: 'definition' },
    looserResultInfos: { type: 'textarea', kind: 'looserResultInfos' },
    looserResultMessage: { type: 'textarea', kind: 'looserResultMessage' },
    looserResultThanks: { type: 'textarea', kind: 'looserResultThanks' },
    looserResultWarning: { type: 'textarea', kind: 'looserResultWarning' },
    looserVideoUrl: { type: 'url', kind: 'looserVideoUrl' },
    mainQuoteVideoUrl: { type: 'url', kind: 'mainQuoteVideoUrl' },
    main: {
        type: 'select', kind: 'main', values: [
            { value: true, label: 'main_enabled_label' },
            { value: false, label: 'main_disabled_label' },
        ]
    },
    mainLogoImage: { type: 'imageUpload', kind: 'mainLogoImage' },
    mainTheme: { type: 'querySelect', kind: 'mainTheme', query: 'FIND_THEMES', dataKey: 'findThemes', convert: (item: any) => ({ value: item.id, label: `${item.code} - ${item.name}` }) },
    mainDfontfamily: { type: 'querySelect', kind: 'mainDfontfamily', query: 'FIND_DFONTFAMILIES', dataKey: 'findDfontfamilies', convert: (item: any) => ({ value: item.id, label: item.name }) },
    maxAttempts: { type: 'number', kind: 'maxAttempts' },
    maxTickets: { type: 'number', kind: 'maxTickets' },
    maxSales: { type: 'number', kind: 'maxSales' },
    maxPaymentAmount: { type: 'number', kind: 'maxPaymentAmount' },
    mission: { type: 'text', kind: 'mission' },
    redirectTarget: { type: 'text', kind: 'redirectTarget'},
    mode: {
        type: 'select', kind: 'mode', values: [
            { value: 'game', label: 'mode_game_label' },
            { value: 'organization', label: 'mode_organization_label' },
            { value: 'campaign', label: 'mode_campaign_label' },
            { value: 'partner', label: 'mode_partner_label' },
            { value: 'tenant', label: 'mode_tenant_label' },
            { value: 'unknown', label: 'mode_unknown_label' },
        ]
    },
    hostMode: {
        type: 'select', kind: 'mode', values: [
            { value: 'redirect', label: 'mode_redirect_label' },
            { value: 'game', label: 'mode_game_label' },
            { value: 'organization', label: 'mode_organization_label' },
            { value: 'campaign', label: 'mode_campaign_label' },
            { value: 'partner', label: 'mode_partner_label' },
            { value: 'tenant', label: 'mode_tenant_label' },
            { value: 'unknown', label: 'mode_unknown_label' },
        ]
    },
    module: { type: 'querySelect', kind: 'module' },
    integration: { type: 'querySelect', kind: 'integration' },
    moduleDefinition: { type: 'json', kind: 'definition' },
    nationality: { type: 'country', kind: 'nationality' },
    nickName: { type: 'text', kind: 'nickName' },
    notificationType: {
        type: 'typeSelect', kind: 'type', values: [
            { value: 'email', label: 'type_email_label' },
            { value: 'sms', label: 'type_sms_label' },
            { value: 'push', label: 'type_push_label' },
        ]
    },
    exporterType: {
        type: 'typeSelect', kind: 'type', values: [
            { value: 'game', label: 'exporter_type_game_label' },
            { value: 'organization', label: 'exporter_type_organization_label' },
            { value: 'partner', label: 'exporter_type_partner_label' },
            { value: 'campaign', label: 'exporter_type_campaign_label' },
            { value: 'tenant', label: 'exporter_type_tenant_label' },
            { value: 'sellergroup', label: 'exporter_type_sellergroup_label' },
            { value: 'seller', label: 'exporter_type_seller_label' },
            { value: 'user', label: 'exporter_type_user_label' },
        ]
    },
    extractor: { type: 'text', kind: 'extractor' },
    transformer: { type: 'text', kind: 'transformer' },
    loader: { type: 'text', kind: 'loader' },
    notifiers: { type: 'textarea', kind: 'notifiers' },
    exportruleParams: { type: 'rawYaml', kind: 'params', height: 300 },
    extractorParams: { type: 'rawYaml', kind: 'extractorParams', height: 300 },
    transformerParams: { type: 'rawYaml', kind: 'transformerParams', height: 300 },
    loaderParams: { type: 'rawYaml', kind: 'loaderParams', height: 300 },
    notificationParams: { type: 'text', kind: 'notificationParams', height: 300 },
    notifyPhone: { type: 'text', kind: 'notifyPhone' },
    objectTags: {
        type: 'tags',
        name: 'tags',
        kind: 'tags',
        tags: [
            { value: 'sport', label: 'tag_svg_sport' },
            { value: 'basket', label: 'tag_svg_basket' },
            { value: 'man' },
            { value: 'nature' },
            { value: 'flower' },
            { value: 'christmas' },
            { value: 'ball' },
            { value: 'dice' },
            { value: 'game' },
            { value: 'play' },
            { value: 'bamboo' },
            { value: 'music' },
            { value: 'background' },
        ],
    },
    odataitemPresets: { type: 'querySelect', kind: 'presets', query: 'FIND_PRESETS_OF_TYPE_ODATAITEM', dataKey: 'findPresetsOfTypeOdataitem', multiple: true, convert: (item: any) => ({ value: item.code, label: item.name }) },
    offer: { type: 'querySelect', kind: 'offer' },
    offerParams: { type: 'textarea', kind: 'params', label: 'field_offerparams_label', helper: 'field_offerparams_helper', placeholder: 'field_offerparams_placeholder' },
    options: { type: 'textarea', kind: 'options' },
    oreminder: { type: 'querySelect', kind: 'oreminder' },
    organization: { type: 'querySelect', kind: 'organization', convert: (item: any) => ({ value: item.id, label: `${item.code} - ${item.name}` }) },
    organizationPresets: { type: 'querySelect', kind: 'presets', query: 'FIND_PRESETS_OF_TYPE_ORGANIZATION', dataKey: 'findPresetsOfTypeOrganization', multiple: true, convert: (item: any) => ({ value: item.code, label: item.name }) },
    owner: { type: 'querySelect', kind: 'owner', query: 'FIND_CUSTOMERS', dataKey: 'findCustomers', convert: (item: any) => ({ value: item.id, label: item.lastName ? `${item.lastName} ${item.firstName}` : item.email }) },
    parameters: { type: 'textarea', kind: 'parameters' },
    params: { type: 'textarea', kind: 'params' },
    participantResultInfos: { type: 'textarea', kind: 'participantResultInfos' },
    participantResultMessage: { type: 'textarea', kind: 'participantResultMessage' },
    participantResultThanks: { type: 'textarea', kind: 'participantResultThanks' },
    participantResultWarning: { type: 'textarea', kind: 'participantResultWarning' },
    participantVideoUrl: { type: 'url', kind: 'participantVideoUrl' },
    partner: { type: 'querySelect', kind: 'partner' },
    pack: { type: 'querySelect', kind: 'pack' },
    project: { type: 'querySelect', kind: 'project' },
    period: { type: 'text', kind: 'period' },
    permanent: {
        type: 'select', kind: 'permanent', values: [
            { value: true, label: 'permanent_enabled_label' },
            { value: false, label: 'permanent_disabled_label' },
        ]
    },
    plan: { type: 'querySelect', kind: 'plan' },
    plannedClosedAt: { type: 'dateTime', kind: 'plannedClosedAt' },
    plannedDonationRatio: { type: 'number', kind: 'plannedDonationRatio' },
    plannedDrawnAt: { type: 'dateTime', kind: 'plannedDrawnAt' },
    plannedStartedAt: { type: 'dateTime', kind: 'plannedStartedAt' },
    plannedTickets: { type: 'number', kind: 'plannedTickets' },
    plansPresets: { type: 'querySelect', kind: 'presets', query: 'FIND_PRESETS_OF_TYPE_PLAN', dataKey: 'findPresetsOfTypeDataquery', multiple: true, convert: (item: any) => ({ value: item.code, label: item.name }) },
    platform: { type: 'querySelect', kind: 'platform', convert: (item: any) => ({ value: item.id, label: `${item.code} - ${item.name}` }) },
    pool: { type: 'text', kind: 'pool' },
    preferredNotificationMethod: {
        type: 'select', kind: 'preferredNotificationMethod', values: [
            { value: 'email', label: 'email' },
            { value: 'phone', label: 'phone' },
        ]
    },
    preminder: { type: 'querySelect', kind: 'preminder' },
    privacyShield: {
        type: 'select', kind: 'privacyShield', values: [
            { value: 'rgpd', label: 'rgpd' },
        ]
    },
    private: {
        type: 'select', kind: 'private', values: [
            { value: true, label: 'private_enabled_label' },
            { value: false, label: 'private_disabled_label' },
        ]
    },
    processorType: { type: 'text', kind: 'processorType' },
    product: { type: 'querySelect', kind: 'product' },
    promise: { type: 'querySelect', kind: 'promise', convert: (item: any) => ({ value: item.id, label: `${item.code}` }) },
    promiseLastReminderVideoUrl: { type: 'url', kind: 'promiseLastReminderVideoUrl' },
    promiseReminderVideoUrl: { type: 'url', kind: 'promiseReminderVideoUrl' },
    provider: { type: 'text', kind: 'provider' },
    public: {
        type: 'select', kind: 'public', values: [
            { value: true, label: 'public_enabled_label' },
            { value: false, label: 'public_disabled_label' },
        ]
    },
    publicPageBackgroundColor: { type: 'text', kind: 'publicPageBackgroundColor' },
    publicPageBackgroundCustom: { type: 'text', kind: 'publicPageBackgroundCustom' },
    publicPageBackgroundImage: { type: 'imageUpload', kind: 'publicPageBackgroundImage' },
    publicPageBackgroundStyle: { type: 'text', kind: 'publicPageBackgroundStyle' },
    publicPageBackgroundTexture: { type: 'text', kind: 'publicPageBackgroundTexture' },
    publicPageBackgroundVideoUrl: { type: 'url', kind: 'publicPageBackgroundVideoUrl' },
    publicPageEffects: { type: 'text', kind: 'publicPageEffects' },
    publicPageModules: { type: 'text', kind: 'publicPageModules' },
    publicPageModulesData: { type: 'text', kind: 'publicPageModulesData' },
    publicPageModulesCustomRaw: { type: 'json', kind: 'publicPageModulesCustomRaw' },
    publicPageModulesDiff: { type: 'text', kind: 'publicPageModulesDiff' },
    recipient: { type: 'text', kind: 'recipient' },
    report: { type: 'textarea', kind: 'report' },
    role: {
        type: 'roleSelect', kind: 'role', values: [
            { value: 'user', label: 'role_user_label' },
            { value: 'admin', label: 'role_admin_label' },
        ]
    },
    runDefinition: { type: 'yaml', kind: 'definition' },
    sale: { type: 'querySelect', kind: 'sale', convert: (item: any) => ({ value: item.id, label: `${item.id} (${item.gameCode} - ${item.paymentAmount / 100} ${item.paymentCurrency})` }) },
    saleMandatoryOptInMessage: { type: 'text', kind: 'saleMandatoryOptInMessage' },
    salePaidVideoUrl: { type: 'url', kind: 'salePaidVideoUrl' },
    salePromisedVideoUrl: { type: 'url', kind: 'salePromisedVideoUrl' },
    scope: {
        type: 'select', kind: 'scope', values: [
            { value: 'local', label: 'scope_local_label' },
            { value: 'national', label: 'scope_national_label' },
            { value: 'multinational', label: 'scope_multinational_label' },
            { value: 'international', label: 'scope_international_label' },
            { value: 'global', label: 'scope_global_label' },
            { value: 'default', label: 'scope_default_label' },
        ]
    },
    achievableScope: { type: 'text', kind: 'scope' },
    translationText: { type: 'textarea', kind: 'text' },
    achievableRule: { type: 'text', kind: 'rule' },
    achievableEvent: { type: 'text', kind: 'event' },
    salesInfo: { type: 'textarea', kind: 'salesInfo' },
    saleitemsPresets: { type: 'querySelect', kind: 'presets', query: 'FIND_PRESETS_OF_TYPE_SALEITEM', dataKey: 'findPresetsOfTypeSaleitem', multiple: true, convert: (item: any) => ({ value: item.code, label: item.name }) },
    scopeLocale: { type: 'locale', kind: 'scopeLocale' },
    script: { type: 'textarea', kind: 'script' },
    scriptUrl: { type: 'url', kind: 'scriptUrl' },
    sellergroup: { type: 'querySelect', kind: 'sellergroup' },
    seller: { type: 'querySelect', kind: 'seller' },
    sender: { type: 'text', kind: 'sender' },
    seoAbstract: { type: 'textarea', kind: 'seoAbstract' },
    seoAuthor: { type: 'text', kind: 'seoAuthor' },
    seoDescription: { type: 'textarea', kind: 'seoDescription' },
    seoKeywords: { type: 'text', kind: 'seoKeywords' },
    seoLang: { type: 'text', kind: 'seoLang' },
    seoSubject: { type: 'text', kind: 'seoSubject' },
    seoTitle: { type: 'text', kind: 'seoTitle' },
    smssender: { type: 'querySelect', kind: 'smssender', convert: (item: any) => ({ value: item.id, label: `${item.phone}` }) },
    sponsoring: {
        type: 'querySelect', kind: 'sponsoring', query: 'FIND_GAME_SPONSORINGS', dataKey: 'findGameSponsorings', options: (x: any) => {
            return { variables: { id: x.defaultValues?.game || '' } };
        }
    },
    sport: { type: 'text', kind: 'sport' },
    sql: { type: 'text', kind: 'sql' },
    startAt: { type: 'dateTime', kind: 'startAt' },
    strategy: { type: 'text', kind: 'strategy' },
    stripeCustomer: { type: 'text', kind: 'stripeCustomer' },
    subcategory: { type: 'text', kind: 'subcategory' },
    subdomain: { type: 'text', kind: 'subdomain' },
    subtitle: { type: 'text', kind: 'subtitle' },
    supportedLocales: { type: 'text', kind: 'supportedLocales' },
    svgFile: { type: 'file', kind: 'svgFile' },
    template: { type: 'text', kind: 'template' },
    templateType: {
        type: 'select', kind: 'type', values: [
            { value: 'email', label: 'email' },
            { value: 'sms', label: 'sms' },
            { value: 'text', label: 'text' },
            { value: 'json', label: 'json' },
        ]
    },
    textareaDefinition: {type: 'textarea', kind: 'definition'},
    fixtureType: {
        type: 'select', kind: 'type', values: [
            { value: 'email', label: 'email' },
            { value: 'sms', label: 'sms' },
            { value: 'text', label: 'text' },
            { value: 'json', label: 'json' },
        ]
    },
    tenant: { type: 'querySelect', kind: 'tenant' },
    achievableset: { type: 'querySelect', kind: 'achievableset' },
    termsPdfFile: { type: 'file', kind: 'termsPdfFile' },
    ticket: { type: 'querySelect', kind: 'ticket', convert: (item: any) => ({ value: item.id, label: `${item.code}` }) },
    grouping: { type: 'querySelect', kind: 'grouping', convert: (item: any) => ({ value: item.id, label: `${item.code} - ${item.name}` }) },
    ticketPriceAmount: { type: 'number', kind: 'ticketPriceAmount' },
    ticketPriceCurrency: { type: 'currency', kind: 'ticketPriceCurrency' },
    treminder: { type: 'querySelect', kind: 'treminder' },
    typename: { type: 'text', kind: 'typename' },
    typeParentPeriod: { type: 'text', kind: 'typeParentPeriod' },
    user: { type: 'querySelect', kind: 'user', convert: (item: any) => ({ value: item.id, label: item.lastName ? `${item.lastName} ${item.firstName}` : item.email }) },
    value: { type: 'text', kind: 'value' },
    varColorBorderPrimary: { type: 'color', kind: 'varColorBorderPrimary' },
    varColorBorderSecondary: { type: 'color', kind: 'varColorBorderSecondary' },
    varColorChoicePrimary: { type: 'color', kind: 'varColorChoicePrimary' },
    varColorChoiceSecondary: { type: 'color', kind: 'varColorChoiceSecondary' },
    varColorFooterPrimary: { type: 'color', kind: 'varColorFooterPrimary' },
    varColorFooterSecondary: { type: 'color', kind: 'varColorFooterSecondary' },
    varColorHeading: { type: 'color', kind: 'varColorHeading' },
    varColorLogo: { type: 'color', kind: 'varColorLogo' },
    varColorNavPrimary: { type: 'color', kind: 'varColorNavPrimary' },
    varColorNavSecondary: { type: 'color', kind: 'varColorNavSecondary' },
    varColorPanelPrimary: { type: 'color', kind: 'varColorPanelPrimary' },
    varColorPanelSecondary: { type: 'color', kind: 'varColorPanelSecondary' },
    varColorPrimary: { type: 'color', kind: 'varColorPrimary' },
    varColorSecondary: { type: 'color', kind: 'varColorSecondary' },
    varColorTextPrimary: { type: 'color', kind: 'varColorTextPrimary' },
    varColorTextSecondary: { type: 'color', kind: 'varColorTextSecondary' },
    varColorTitle: { type: 'color', kind: 'varColorTitle' },
    variables: { type: 'textarea', kind: 'variables' },
    varsCss: { type: 'textarea', kind: 'varsCss' },
    vatNumber: { type: 'text', kind: 'vatNumber' },
    videoTags: {
        type: 'tags',
        kind: 'tags',
        tags: [
            { value: 'primary', label: 'tag_video_primary_label' },
            { value: 'secondary', label: 'tag_video_secondary_label' },
            { value: 'tertiary', label: 'tag_video_tertiary_label' },
            { value: 'logos', label: 'tag_video_logos_label' },
            { value: 'sponsors', label: 'tag_video_sponsors_label' },
            { value: 'sponsors_logos', label: 'tag_video_sponsors_logos_label' },
            { value: 'events', label: 'tag_video_events_label' },
            { value: 'members', label: 'tag_video_members_label' },
            { value: 'results', label: 'tag_video_results_label' },
            { value: 'hidden', label: 'tag_video_hidden_label' },
        ],
    },
    wdefinition: { type: 'querySelect', kind: 'wdefinition', query: 'FIND_WDEFINITIONS', dataKey: 'findWdefinitions', convert: (item: any) => ({ value: item.id, label: `${item.key}` }) },
    winnerResultInfos: { type: 'textarea', kind: 'winnerResultInfos' },
    winnerResultMessage: { type: 'textarea', kind: 'winnerResultMessage' },
    winnerResultThanks: { type: 'textarea', kind: 'winnerResultThanks' },
    winnerResultWarning: { type: 'textarea', kind: 'winnerResultWarning' },
    winnerVideoUrl: { type: 'url', kind: 'winnerVideoUrl' },
    mediumRectangleBanner2Image: { type: 'imageUpload', kind: 'mediumRectangleBanner2Image' },
    mediumRectangleBannerImage: { type: 'imageUpload', kind: 'mediumRectangleBannerImage' },
    mobileBannerImage: { type: 'imageUpload', kind: 'mobileBannerImage' },
    tabletBannerImage: { type: 'imageUpload', kind: 'tabletBannerImage' },
    mediumRectangleMainImage: { type: 'imageUpload', kind: 'mediumRectangleMainImage' },
    mediumRectangleThumbnailImage: { type: 'imageUpload', kind: 'mediumRectangleThumbnailImage' },
    squareBanner2Image: { type: 'imageUpload', kind: 'squareBanner2Image' },
    squareBannerImage: { type: 'imageUpload', kind: 'squareBannerImage' },
    squareMainImage: { type: 'imageUpload', kind: 'squareMainImage' },
    squareThumbnailImage: { type: 'imageUpload', kind: 'squareThumbnailImage' },
    largeLeaderboardBanner2Image: { type: 'imageUpload', kind: 'largeLeaderboardBanner2Image' },
    largeLeaderboardBannerImage: { type: 'imageUpload', kind: 'largeLeaderboardBannerImage' },
    largeLeaderboardMainImage: { type: 'imageUpload', kind: 'largeLeaderboardMainImage' },
    largeLeaderboardThumbnailImage: { type: 'imageUpload', kind: 'largeLeaderboardThumbnailImage' },
    largeRectangleBanner2Image: { type: 'imageUpload', kind: 'largeRectangleBanner2Image' },
    largeRectangleBannerImage: { type: 'imageUpload', kind: 'largeRectangleBannerImage' },
    largeRectangleMainImage: { type: 'imageUpload', kind: 'largeRectangleMainImage' },
    largeRectangleThumbnailImage: { type: 'imageUpload', kind: 'largeRectangleThumbnailImage' },
    wideSkyscraperBanner2Image: { type: 'imageUpload', kind: 'wideSkyscraperBanner2Image' },
    wideSkyscraperBannerImage: { type: 'imageUpload', kind: 'wideSkyscraperBannerImage' },
    wideSkyscraperMainImage: { type: 'imageUpload', kind: 'wideSkyscraperMainImage' },
    wideSkyscraperThumbnailImage: { type: 'imageUpload', kind: 'wideSkyscraperThumbnailImage' },
    smallSquareBanner2Image: { type: 'imageUpload', kind: 'smallSquareBanner2Image' },
    smallSquareBannerImage: { type: 'imageUpload', kind: 'smallSquareBannerImage' },
    smallSquareMainImage: { type: 'imageUpload', kind: 'smallSquareMainImage' },
    smallSquareThumbnailImage: { type: 'imageUpload', kind: 'smallSquareThumbnailImage' },
    mobileLeaderboardBanner2Image: { type: 'imageUpload', kind: 'mobileLeaderboardBanner2Image' },
    mobileLeaderboardBannerImage: { type: 'imageUpload', kind: 'mobileLeaderboardBannerImage' },
    mobileLeaderboardMainImage: { type: 'imageUpload', kind: 'mobileLeaderboardMainImage' },
    mobileLeaderboardThumbnailImage: { type: 'imageUpload', kind: 'mobileLeaderboardThumbnailImage' },
    skyscraperBanner2Image: { type: 'imageUpload', kind: 'skyscraperBanner2Image' },
    skyscraperBannerImage: { type: 'imageUpload', kind: 'skyscraperBannerImage' },
    skyscraperMainImage: { type: 'imageUpload', kind: 'skyscraperMainImage' },
    skyscraperThumbnailImage: { type: 'imageUpload', kind: 'skyscraperThumbnailImage' },
    leaderboardBanner2Image: { type: 'imageUpload', kind: 'leaderboardBanner2Image' },
    leaderboardBannerImage: { type: 'imageUpload', kind: 'leaderboardBannerImage' },
    leaderboardMainImage: { type: 'imageUpload', kind: 'leaderboardMainImage' },
    leaderboardThumbnailImage: { type: 'imageUpload', kind: 'leaderboardThumbnailImage' },
    mediumRectangleBanner2ImageUrl: { type: 'url', kind: 'mediumRectangleBanner2ImageUrl' },
    mediumRectangleBannerImageUrl: { type: 'url', kind: 'mediumRectangleBannerImageUrl' },
    mediumRectangleMainImageUrl: { type: 'url', kind: 'mediumRectangleMainImageUrl' },
    mediumRectangleThumbnailImageUrl: { type: 'url', kind: 'mediumRectangleThumbnailImageUrl' },
    squareBanner2ImageUrl: { type: 'url', kind: 'squareBanner2ImageUrl' },
    squareBannerImageUrl: { type: 'url', kind: 'squareBannerImageUrl' },
    squareMainImageUrl: { type: 'url', kind: 'squareMainImageUrl' },
    squareThumbnailImageUrl: { type: 'url', kind: 'squareThumbnailImageUrl' },
    largeLeaderboardBanner2ImageUrl: { type: 'url', kind: 'largeLeaderboardBanner2ImageUrl' },
    largeLeaderboardBannerImageUrl: { type: 'url', kind: 'largeLeaderboardBannerImageUrl' },
    largeLeaderboardMainImageUrl: { type: 'url', kind: 'largeLeaderboardMainImageUrl' },
    largeLeaderboardThumbnailImageUrl: { type: 'url', kind: 'largeLeaderboardThumbnailImageUrl' },
    largeRectangleBanner2ImageUrl: { type: 'url', kind: 'largeRectangleBanner2ImageUrl' },
    largeRectangleBannerImageUrl: { type: 'url', kind: 'largeRectangleBannerImageUrl' },
    largeRectangleMainImageUrl: { type: 'url', kind: 'largeRectangleMainImageUrl' },
    largeRectangleThumbnailImageUrl: { type: 'url', kind: 'largeRectangleThumbnailImageUrl' },
    wideSkyscraperBanner2ImageUrl: { type: 'url', kind: 'wideSkyscraperBanner2ImageUrl' },
    wideSkyscraperBannerImageUrl: { type: 'url', kind: 'wideSkyscraperBannerImageUrl' },
    wideSkyscraperMainImageUrl: { type: 'url', kind: 'wideSkyscraperMainImageUrl' },
    wideSkyscraperThumbnailImageUrl: { type: 'url', kind: 'wideSkyscraperThumbnailImageUrl' },
    smallSquareBanner2ImageUrl: { type: 'url', kind: 'smallSquareBanner2ImageUrl' },
    smallSquareBannerImageUrl: { type: 'url', kind: 'smallSquareBannerImageUrl' },
    smallSquareMainImageUrl: { type: 'url', kind: 'smallSquareMainImageUrl' },
    smallSquareThumbnailImageUrl: { type: 'url', kind: 'smallSquareThumbnailImageUrl' },
    mobileLeaderboardBanner2ImageUrl: { type: 'url', kind: 'mobileLeaderboardBanner2ImageUrl' },
    mobileLeaderboardBannerImageUrl: { type: 'url', kind: 'mobileLeaderboardBannerImageUrl' },
    mobileLeaderboardMainImageUrl: { type: 'url', kind: 'mobileLeaderboardMainImageUrl' },
    mobileLeaderboardThumbnailImageUrl: { type: 'url', kind: 'mobileLeaderboardThumbnailImageUrl' },
    skyscraperBanner2ImageUrl: { type: 'url', kind: 'skyscraperBanner2ImageUrl' },
    skyscraperBannerImageUrl: { type: 'url', kind: 'skyscraperBannerImageUrl' },
    skyscraperMainImageUrl: { type: 'url', kind: 'skyscraperMainImageUrl' },
    skyscraperThumbnailImageUrl: { type: 'url', kind: 'skyscraperThumbnailImageUrl' },
    leaderboardBanner2ImageUrl: { type: 'url', kind: 'leaderboardBanner2ImageUrl' },
    leaderboardBannerImageUrl: { type: 'url', kind: 'leaderboardBannerImageUrl' },
    leaderboardMainImageUrl: { type: 'url', kind: 'leaderboardMainImageUrl' },
    leaderboardThumbnailImageUrl: { type: 'url', kind: 'leaderboardThumbnailImageUrl' },
    logoImageUrl: { type: 'url', kind: 'logoImageUrl' },
    mainImageUrl: { type: 'url', kind: 'mainImageUrl' },
    bannerImageUrl: { type: 'url', kind: 'bannerImageUrl' },
    mobileBannerImageUrl: { type: 'url', kind: 'mobileBannerImageUrl' },
    tabletBannerImageUrl: { type: 'url', kind: 'tabletBannerImageUrl' },
    banner2ImageUrl: { type: 'url', kind: 'banner2ImageUrl' },
    logoAltImageUrl: { type: 'url', kind: 'logoAltImageUrl' },
    thumbnailImageUrl: { type: 'url', kind: 'thumbnailImageUrl' },
    whitelistedAds: { type: 'querySelect', kind: 'whitelistedAds', query: 'FIND_ADS', dataKey: 'findAds', multiple: true, convert: (item: any) => ({ value: item.id, label: `${item.code}-${item.name}` }) },
    whitelistedAdvertisers: { type: 'querySelect', kind: 'whitelistedAdvertisers', query: 'FIND_ADVERTISERS', dataKey: 'findAdvertisers', multiple: true, convert: (item: any) => ({ value: item.id, label: item.name }) },
    whitelistedAffiliaters: { type: 'querySelect', kind: 'whitelistedAffiliaters', query: 'FIND_AFFILIATERS', dataKey: 'findAffiliaters', multiple: true, convert: (item: any) => ({ value: item.id, label: item.name }) },
    whitelistedTags: { type: 'querySelect', kind: 'whitelistedTags', query: 'FIND_TAGS_BY_TYPE', dataKey: 'findTagsByType', multiple: true, options: () => ({variables: {type: 'ad'}}), convert: (item: any) => ({ value: item.code, label: item.name }) },
    whitelistedCountries: { type: 'querySelect', kind: 'whitelistedCountries', query: 'FIND_TCOUNTRIES', dataKey: 'findTcountries', multiple: true, convert: (item: any) => ({ value: item.code, label: item.name }) },
};

export default fields;
