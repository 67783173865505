const t = {
    icon: 'fa-toolbox',
    menus: {
        main: {section: 'settings2', priority: 40},
    },
    new: {
        attributes: [
            'level', 'organization', 'game', 'directParentTenant', 'module',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$module!', '$integrationLevel!',
                ]],
                ['block', [
                    '$game', '$organization',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'level', 'organization', 'game', 'directParentTenant', 'module',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$module!', '$integrationLevel!',
                ]],
                ['block', [
                    '$game', '$organization',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        extraToolbar: true,
        properties: [
            'id', 'name', 'level', 'organization', 'organizationName',
        ],
    },
    list: {
        columns: [
            {id: 'createdAt', label: 'column_createdat_label', component: 'date', flex: 0, width: 150} as any,
            {id: 'game', label: 'column_game_label', component: 'game'},
            {id: 'organization', label: 'column_organization_label', component: 'organization'},
            {id: 'name', label: 'column_name_label', flex: 0, width: 100},
            {id: 'status', label: 'column_status_label', flex: 0, width: 100},
            {id: 'level', label: 'column_level_label', flex: 0, width: 100},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
        filters: {
            gamelevel: {},
            orglevel: {},
            orglevelhelloasso: {},
            orglevelstripe: {},
            orglevelpaypal: {},
            orglevelsumup: {},
            orglevellydia: {},
            orglevelgocardless: {},
            all: {},
        },
    },
};

export default t;
