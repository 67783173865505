const t = {
    icon: 'fa-bell',
    menus: {
        main: { section: 'settings3', priority: 46 },
    },
    display: {
        properties: [
            'id', 'createdAt', 'status', 'sale', 'gameCode', 'gameName', 'game', 'organization', 'organizationCode', 'organizationName', 'partnerCode', 'partnerName', 'partner', 'tenantCode', 'tenantName', 'tenant', 'provider', 'type', 'eventType',
        ],
    },
    list: {
        columns: [
            { id: 'createdAt', label: 'column_createdat_label', component: 'date', flex: 0, width: 200 },
            { id: 'provider', label: 'column_provider_label', flex: 0, width: 90 },
            { id: 'type', label: 'column_type_label', flex: 0, width: 90 },
            { id: 'eventType', label: 'column_eventtype_label', flex: 0, width: 90 },
            { id: 'status', label: 'column_status_label', flex: 0, width: 100 },
            { id: 'game', label: 'column_game_label', component: 'game', flex: 0, width: 250 },
            { id: 'sale', label: 'column_sale_label', component: 'sale', flex: 1 },
        ],
        globalActions: [],
        actions: ['display', 'delete'],
        filters: {
            helloassoPayment: {},
            all: {},
        }
    },
};

export default t;
