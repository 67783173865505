const t = {
    icon: 'fa-toolbox',
    menus: {
        main: {section: 'settings2', priority: 41},
    },
    new: {
        attributes: [
            'integration', 'organization', 'game',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$integration!',
                ]],
                ['block', [
                    '$game', '$organization',
                ]],
            ]],
            '$submit',
        ],
    },
    edit: {
        attributes: [
            'integration', 'organization', 'game',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$integration!',
                ]],
                ['block', [
                    '$game', '$organization',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        properties: [
            'id', 'integration', 'game', 'gameName', 'gameCode', 'organization', 'organizationName',
        ],
    },
    list: {
        columns: [
            {id: 'createdAt', label: 'column_createdat_label', component: 'date', flex: 0, width: 150} as any,
            {id: 'integration', label: 'column_integration_label', component: 'integration'},
            {id: 'game', label: 'column_game_label', component: 'game'},
            {id: 'organization', label: 'column_organization_label', component: 'organization'},
            {id: 'status', label: 'column_status_label', flex: 0, width: 100},
        ],
        globalActions: ['add'],
        actions: ['display', 'edit', 'delete'],
    },
};

export default t;
