const t = {
    icon: 'fa-money-bill-wave',
    menus: {
        main: {section: 'activity', priority: 25},
    },
    edit: {
        attributes: [
            'owner', 'campaign', 'tickets', 'beneficiary', 'project', 'pack', 'godfather', 'params',
        ],
        content: [
            ['row', [
                ['block', [
                    '!$game!', '$owner!', '$tickets',
                ]],
                ['block', [
                    '$godfather', '$beneficiary', '$project',
                ]],
            ]],
            '$submit',
        ],
    },
    display: {
        toolbar: true,
        body: true,
        properties: [
            'id', 'status', 'campaignName', 'gameName', 'partnerName', 'organizationName', 'tickets', 'paymentAmount', 'paymentProviderFeeAmount',
            'marketPlaceFeeAmount', 'transactionFeeAmount', 'totalFeeAmount', 'customerCollectedAmount', 'billingRule', 'planCode',
            'godfather', 'godfatherCode', 'godfatherEmail', 'godfatherPhone',
        ],
    },
    list: {
        columns: [
            {id: 'createdAt', label: 'column_createdat_label', component: 'date', flex: 0, width: 160},
            {id: 'status', label: 'column_status_label', flex: 0, width: 100, component: 'sale_status'},
            {id: 'email', label: 'column_email_label', flex: 0, width: 240},
            {id: 'paymentAmount', type: 'number', label: 'column_paymentamount_label', flex: 0, width: 115, component: 'payment_amount'},
            {id: 'tickets', type: 'number', label: 'column_tickets_label', flex: 0, width: 110},
            {id: 'donation', type: 'number', label: 'column_donation_label', flex: 0, width: 100, component: 'donation_amount'},
            {id: 'gameCode', label: 'column_game_code_label', component: 'game'},
            {id: 'godfather', label: 'column_godfather_label', component: 'godfather', flex: 0, width: 200},
        ],
        globalActions: [],
        actions: ['display', 'edit', 'go_public_page', 'delete'],
        filters: {
            paid: {},
            created: {},
            promised: {},
            failed: {},
            canceled: {},
            flex: {},
            flexNotBilled: {},
            paidHelloasso: {},
            paidStripe: {},
            paidPaypal: {},
            all: {},
        }
    },
    types: {
        externalevent: {
            new: {},
            list: {
                moveable: false,
                columns: [
                    { id: 'createdAt', label: 'column_createdat_label', component: 'date', flex: 0, width: 200 },
                    { id: 'provider', label: 'column_provider_label', flex: 0, width: 150 },
                    { id: 'type', label: 'column_type_label', flex: 0, width: 180 },
                    { id: 'eventType', label: 'column_eventtype_label', flex: 0, width: 150 },
                    { id: 'status', label: 'column_status_label', flex: 1 },
                ],
                globalActions: [],
                actions: ['display'],
            },
        },
    },
};

export default t;
