export const CREATE_INTEGRATIONREFRESH = (gql: any) => gql`
    mutation($data: CreateIntegrationrefreshInput!) {
        createIntegrationrefresh(data: $data) {
            id
        }
    }
`;
export const DELETE_INTEGRATIONREFRESH = (gql: any) => gql`
    mutation($id: ID!) {
        deleteIntegrationrefresh(id: $id) {
            id
        }
    }
`;
export const GET_INTEGRATIONREFRESH = (gql: any) => gql`
    query($id: ID!) {
        getIntegrationrefresh(id: $id) {
            id
            integration
            integrationCode
            integrationName
            game
            gameCode
            gameName
            organization
            organizationName
            tenant
            tenantCode
            tenantName
            createdAt
            updatedAt
            status
        }
    }
`;
export const FIND_INTEGRATIONREFRESHS = (gql: any) => gql`
    query findIntegrationrefreshs($offset: String, $limit: Int) {
        findIntegrationrefreshs(offset: $offset, limit: $limit) {
            cursor
            count
            items {
                id
                integration
                integrationCode
                integrationName
                game
                gameCode
                gameName
                organization
                organizationName
                tenant
                tenantCode
                tenantName
                createdAt
                updatedAt
                status
            }
        }
    }
`;
export const SEARCH_INTEGRATIONREFRESHS = (gql: any) => gql`
    query searchIntegrationrefreshs($offset: String, $limit: Int, $sort: String = "createdAt:desc") {
        searchIntegrationrefreshs(offset: $offset, limit: $limit, sort: $sort) {
            cursor
            count
            items {
                id
                integration
                integrationCode
                integrationName
                game
                gameCode
                gameName
                organization
                organizationName
                tenant
                tenantCode
                tenantName
                createdAt
                updatedAt
                status
            }
        }
    }
`;
